import React from "react";
import "./index.scss";

function LandingPage() {
  return (
    <section className="landingPage">
      <section className="brandContent animate__animated animate__rotateInDownLeft">
        <p className="superHeading">I'm</p>
        <h1 className="heading animate__animated animate__delay-3s animate__heartBeat">
          Vishnu Prasad
        </h1>
        <p className="subHeading">
          Solution Architect, Digital Transformation Consultant
        </p>
      </section>
      <section className="landingFooter">
        <svg
          class="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </section>
    </section>
  );
}

export default LandingPage;
