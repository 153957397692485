import React from "react";
import "./index.scss";
function Contact() {
  return (
    <section className="contactMe" id="contact">
      <section>
        <h2>Contact Me</h2>
        <div>
          <a
            href="mailto:hello@webvish.com?subject=New%20Opportunity&body=Hi Vishnu, I have an existing opportunity for you."
            title="Mail me"
          >
            <span class="material-symbols-outlined">mail</span>
            hello@webvish.com
          </a>
        </div>
      </section>
    </section>
  );
}

export default Contact;
