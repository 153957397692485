import React from "react";
import "./index.scss";
import skills from "./skillslist.json";

function SkillsTools() {
  return (
    <section className="skillToolsContainer" id='skills'>
      <h2>Skills & Tools</h2>
      <section className="listContainer">
        {skills.skills.map((ele, index) => {
        return  <div key={ele.key}>
            <img
              alt={ele.alt}
              src={ele.data}
            />
            <p>{ele.key}</p>
          </div>;
        })}
      </section>
    </section>
  );
}

export default SkillsTools;
