import "./App.scss";
import AboutMe from "./component/AboutMe";
import Contact from "./component/Contact";
import Experience from "./component/Experience";
import Header from "./component/Header";
import LandingPage from "./component/LandingPage";
import SkillsTools from "./component/SkillsTools";
import 'animate.css';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <LandingPage />
        <AboutMe />
        <Experience />
        <SkillsTools />
      </main>
      <Contact />
      <footer>
        <section>
          <span>
            All rights are Reserved &copy; 2024. <strong>Vishnu Prasad</strong>
          </span>
        </section>
      </footer>
    </div>
  );
}

export default App;
