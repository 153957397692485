import React from "react";
import "./index.scss";
import LogoImg from "../../assets/image/logo.svg";

function Header() {
  return (
    <header className="mainHeader">
      <section>
        <section>
          <a href="/">
            <section className="mainBrand">
              <img src={LogoImg} alt="Logo" />
              <h1>WebVish</h1>
            </section>
          </a>
        </section>
        <nav className="mainNav">
        <a href='#about'>About</a>
        <a href='#experience'>Experience</a>
        <a href='#skills'>Skills</a>            
        </nav>
        <section className="contactBtn">
          <a href='#contact' className="button">Contact</a>
        </section>
      </section>
    </header>
  );
}

export default Header;
