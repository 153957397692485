import React from "react";
import "./index.scss";
function Experience() {
  return (
    <>
      <section className="experienceContainer"  id="experience">
        <section>
          <h2>Experience</h2>
          <p>
            With over a decade of experience, I've been integral in various
            phases of the software development lifecycle. From conducting
            architecture assessments to leading teams in implementing seamless
            migrations and designing scalable Microservices architectures.
          </p>
        </section>
      </section>
      <section className="experience">
        <p>
          I specialize in architecture assessments, code assessments, system
          designs, and training teams to excel in their projects. My hands-on
          experience in frontend and backend technologies enables me to provide
          comprehensive solutions across multiple accounts.
        </p>
        <h2>Responsibilities</h2>
      </section>
    </>
  );
}

export default Experience;
