import React from "react";
import "./index.scss";

function AboutMe() {
  return (
    <section className="aboutMe" id="about">
        <h2>About Me</h2>
        <p>
          A seasoned Software Architect with more than 13 years of hands-on
          experience. Dive into my portfolio to explore how I excel in crafting
          meticulously designed Microservices and harnessing the power of
          multi-cloud environments to drive scalable solutions.
        </p>
    </section>
  );
}

export default AboutMe;
